import { axios } from '../plugins/axios'

//猪崽详情
export function getPigDetail(data) {
  return axios.request({
    url: '/mch/livestock/pig',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//确认喂养
export function getFeed(data) {
  return axios.request({
    url: '/mch/livestock/feed',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//协议
export function getXieyi(data) {
  return axios.request({
    url: '/api/Publics/get_xieyi',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//确认档案
export function File(data) {
  return axios.request({
    url: 'public/pig_info',
    method: 'Get',
    params: {
      ...data
    }
  })
}

//喂养记录
export function record(data) {
  return axios.request({
    url: '/api/public/feed_list',
    method: 'Get',
    params: {
      ...data
    }
  })
}

// 验证码
export function sendSms(data) {
  return axios.request({
    url: '/api/Publics/sendSmsH5',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//  验证好友
export function bindFriend(data) {
  return axios.request({
    url: '/api/Publics/bindFriend',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 获取直播地址
export function getLiveUrl(data) {
  return axios.request({
    url: '/api/Publics/getLiveUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 获取下载地址
export function getDownUrl(data) {
  return axios.request({
    url: '/api/Publics/getDownUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}

// 分享
export function shareUse(data) {
  return axios.request({
    url: '/api/public/share_use',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 获取农户下载地址
export function getMchDownUrl(data) {
  return axios.request({
    url: '/mch/Publics/getDownUrl',
    method: 'POST',
    params: {
      ...data
    }
  })
}
// 打印页面，
export function dump_pig(data) {
  return axios.request({
    url: '/api/public/dump_pig',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//获取萤石云token
export function getToken() {
  return axios.request({
    url: '/api/public/get_token',
    method: 'get'
  })
}

// 游乐场 猪崽档案
export function pigInfos(data) {
  return axios.request({
    url: '/api/public/pig_info',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 游乐场 喂养记录
export function feedList(data) {
  return axios.request({
    url: '/api/public/feed_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// 开关
export function ranchData(data) {
  return axios.request({
    url: '/api/public/ranch',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//h5动态详情
export function dynamicData(data) {
  return axios.request({
    url: '/public/dynamic_info',
    method: 'GET',
    params: {
      ...data
    }
  })
}

// Token
export function getYsyToken() {
  return axios.request({
    url: "/public/get_token",
    method: "GET",
  });
}




//监控信息
export function piginfo(data) {
  return axios.request({
    url: '/public/pig_info',
    method: 'GET',
    params: {
      ...data
    }
  })
}


//开始调整监控
export function monitoringstart(data) {
  return axios.request({
    url: '/api/monitoring/start',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//结束调整监控
export function monitoringstop(data) {
  return axios.request({
    url: '/api/monitoring/stop',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//重置调整监控
export function monitoringpreset(data) {
  return axios.request({
    url: '/api/monitoring/preset',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//抽奖列表
export function lucklist(data) {
  return axios.request({
    url: '/api/adv/luck_list',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//抽奖结果

export function luckname(data) {
  return axios.request({
    url: '/api/adv/luck',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//获取省份
export function getProvince(data) {
  return axios.request({
    url: '/api/Publics/getProvince',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//获取市、区
export function getCityArea(data) {
  return axios.request({
    url: '/api/Publics/getCityArea',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//收货地址
export function filladdr(data) {
  return axios.request({
    url: '/api/adv/fill_addr',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//收货地址
export function Publics(data) {
  return axios.request({
    url: '/api/Publics/bindFriend',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//验证码
export function PublicssendSms(data) {
  return axios.request({
    url: '/api/Publics/sendSms',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//  验证好友
export function bindfriend(data) {
  return axios.request({
    url: '/api/public/bind_friend',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//验证码登录

export function codelogin(data) {
  return axios.request({
    url: '/api/adv/code_login',
    method: 'POST',
    params: {
      ...data
    }
  })
}


//微信登录
export function wxlogin(data) {
  return axios.request({
    url: '/h5/wx_login',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//赠送猪仔卡
export function storepig(data) {
  return axios.request({
    url: '/api/store/give_pig_card',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//猪仔倒计时
export function countdown(data) {
  return axios.request({
    url: '/api/pig_card/countdown',
    method: 'POST',
    params: {
      ...data
    }
  })
}

//猪仔价格
export function pig_list(data) {
  return axios.request({
    url: '/api/public/pig_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//喂养价格
export function meal_list(data) {
  return axios.request({
    url: '/api/public/meal_list',
    method: 'GET',
    params: {
      ...data
    }
  })
}

//屠宰价格
export function butcher_config(data) {
  return axios.request({
    url: '/public/butcher_config',
    method: 'GET',
    params: {
      ...data
    }
  })
}