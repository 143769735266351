// 通用接口地址
// 环境的切换
let baseUrl
if (process.env.NODE_ENV == 'development') {
  // baseUrl = 'http://api.hxnc.ikuyoo.cn'
  baseUrl = 'https://api.hxnc.cc'
} else {
  // baseUrl = 'http://api.hxnc.ikuyoo.cn'
  baseUrl = 'https://api.hxnc.cc'
}

// 萤石云  获取token 接口地址
let ysyURL = 'https://open.ys7.com/api/lapp/token/get'

// 萤石云  appKey
let appKey = 'b435ada1862748398f276255e43b2a9e'

//萤石云  appSecret
let appSecret = 'f0c99900a99a9763dec36841f76fa5df'
export default { baseUrl, ysyURL, appKey, appSecret }
